import React from 'react';

export const StoryCardSkeleton = () => {
  return (
    <div className="p-4">
      <div className="animate-pulse">
        <div className="flex space-x-4">
          <div className="flex-1 space-y-6 py-1">
            <div className="space-y-3">
              <div className="h-3 w-28 bg-slate-200 rounded"></div>
              <div className="h-3 bg-slate-200 rounded"></div>
            </div>
            <div className="space-y-3">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-3 bg-slate-200 rounded col-span-2"></div>
                <div className="h-3 bg-slate-200 rounded col-span-1"></div>
              </div>
              <div className="h-3 bg-slate-200 rounded"></div>
            </div>
          </div>
          <div className="rounded-md bg-slate-200 h-20 w-20"></div>
        </div>
        <div className="pt-2 space-y-3">
          <div className="h-3 bg-slate-200 rounded"></div>
          <div className="grid grid-cols-3 gap-4">
            <div className="h-3 bg-slate-200 rounded col-span-2"></div>
            <div className="h-3 bg-slate-200 rounded col-span-1"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
