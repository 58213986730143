import React, { FunctionComponent, useEffect, useState } from 'react';
import { StoryTweet } from './story-tweet';
import './StoryTweetCard.scss';

export interface StoryTweetCardProps {
  tweet: StoryTweet;
  openClickCommand?: () => void;
  showLabel?: boolean;
}

export const StoryTweetCard: FunctionComponent<StoryTweetCardProps> = ({
  tweet,
  openClickCommand,
  showLabel = true,
}) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setTimeout(() => setExpanded(true), 0);
  }, [setExpanded]);

  const onClick = () => {
    if (openClickCommand) {
      openClickCommand();
    }
  };

  const getLabel = () => {
    if (!showLabel) return;
    return (
      <div className="StoryTweetCard__title bold-h3 black-text align-left">
        What people are saying...
      </div>
    );
  };

  if (!tweet) {
    return <div />;
  }

  return (
    <div className={'StoryTweetCard ' + (expanded ? 'opened' : '')}>
      {getLabel()}
      <div className="StoryTweetCard__content">
        <div>
          <img
            className="StoryTweetCard__avatar"
            src={tweet.imageUrl}
            alt="Not available"
            onClick={() => onClick()}
          />
        </div>
        <div className="StoryTweetCard__body">
          <span className="StoryTweetCard__user" onClick={() => onClick()}>
            {tweet.name}
          </span>
          <span dangerouslySetInnerHTML={{ __html: tweet.bodyHtml }} />
        </div>
      </div>
    </div>
  );
};
