import React, { FunctionComponent } from 'react';
import './StoryCardPlaceholder.scss';

export interface StoryCardPlaceholderProps {
  isPreview?: boolean;
}

export const StoryCardPlaceholder: FunctionComponent<StoryCardPlaceholderProps> = ({
  isPreview,
}) => {
  const classNames = isPreview ? 'mod-preview' : '';
  return (
    <div
      data-testid="report-card-placeholder"
      className={'report-story-card-placeholder ' + classNames}
    >
      <div className="report-story-card-body-placeholder">
        <article className="story-card-placeholder clearfix">
          <header>
            <div>
              <div className="image-container-placeholder placeholder float-right" />
            </div>
            <h2 className="title">
              <div className="title-line1-placeholder placeholder" />
              <div className="title-line2-placeholder placeholder" />
              <div className="title-line3-placeholder placeholder" />
            </h2>
            <div className="details">
              <span className="date-pub">
                <time> </time>
              </span>
            </div>
          </header>
          <div className="excerpt-placeholder">
            <div className="excerpt-placeholder-line1 excerpt-placeholder-line placeholder" />
            <div className="excerpt-placeholder-line2 excerpt-placeholder-line placeholder" />
            <div className="excerpt-placeholder-line3 excerpt-placeholder-line placeholder" />
            <div className="excerpt-placeholder-line4 excerpt-placeholder-line placeholder" />
            <div className="excerpt-placeholder-line5 excerpt-placeholder-line placeholder" />
            <div className="excerpt-placeholder-line6 excerpt-placeholder-line placeholder" />
          </div>
          {!isPreview && (
            <div className="share-options-placeholder">
              <div className="btn-share share-button-placeholder placeholder" />
              <div className="btn-share share-button-placeholder placeholder" />
              <div className="btn-share share-button-placeholder placeholder" />
              <div className="btn-share share-button-placeholder placeholder" />
            </div>
          )}
        </article>
      </div>
    </div>
  );
};
